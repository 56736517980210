.select-region-popup {

  @media (min-width: 576px) {
    .modal-dialog {
        max-width: 450px;
    }
  }

  .modal-content {
    border-radius: 10px;
  }

  .modal-body {
    display: flex;
    align-items: center;
    flex-direction: column;

    .region-icon {
      width: 125px;
    }

    h1 {
      color: #000;
      font-weight: 700;
      font-size: 24px;
      line-height: 1;
      margin-top: 30px;
      margin-bottom: 0;
      text-transform: none;
    }

    p {
      margin-top: 12px;
      color: #435160;
      font-size: 18px;
      text-align: center;
    }

    .button {
      width: 100%;
      margin-top: 24px;
      border-radius: 10px;
      padding: 12px;
      background-color: #2979ff;
    }
  }

  .form-login {
    width: 100%;
    padding: 0 24px;
  }

  .form-group {
    margin-top: 12px;
    margin-bottom: 0;
    width: 100%;
  }

  .mt-input-radio {
    .mt-input-radio_color {
      width: 21px;
      height: 21px;
      border: 1px solid #babdbe;

      &:after {
        top: 4px;
        left: 4px;
        width: 11px;
        height: 11px;
      }
    }

    .mt-input-radio__label {
      font-size: 14px;
      font-weight: 600;
      color: #435160;
    }
  }
}